
<template>
	<div
		v-if="active_dropdown_filters.length"
		class="flex flex--wrap flex--gap-medium"
	>
		<p>Filtering by:</p>
		<button
			v-for="status in project_status.active"
			:key="'active-status-' + status.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( status, 'project_status' )"
		>
			{{ status.label }}
		</button>
		<button
			v-for="type in project_type.active"
			:key="'active-type-' + type.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( type, 'project_status' )"
		>
			{{ type.label }}
		</button>
		<button
			v-for="location in project_location.active"
			:key="'active-location-' + location.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( location, 'project_location' )"
		>
			{{ location.label }}
		</button>
		<button
			v-for="deliverable in deliverables.active"
			:key="'active-deliverable-' + deliverable.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( deliverable, 'deliverables' )"
		>
			{{ deliverable.label }}
		</button>
		<button
			v-for="option in completion_date.active"
			:key="'active-completion-date-' + option.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( option, 'completion_date' )"
		>
			{{ option.label }}
		</button>
		<button
			v-for="office in lead_office.active"
			:key="'active-lead-office-' + office.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( office, 'lead_office' )"
		>
			{{ office.label }}
		</button>
		<button
			v-for="company in client.active"
			:key="'active-client-' + company.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( company, 'client' )"
		>
			{{ company.label }}
		</button>
		<button
			v-for="company in developer.active"
			:key="'active-developer-' + company.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( company, 'developer' )"
		>
			{{ company.label }}
		</button>
		<button
			v-for="company in development_consultant.active"
			:key="'active-development-consultant-' + company.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( company, 'development_consultant' )"
		>
			{{ company.label }}
		</button>
		<button
			v-for="company in creative_agency.active"
			:key="'active-creative-agency-' + company.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( company, 'creative_agency' )"
		>
			{{ company.label }}
		</button>
		<button
			v-for="company in sales_marketing.active"
			:key="'active-sales-marketing-' + company.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( company, 'sales_marketing' )"
		>
			{{ company.label }}
		</button>
		<button
			v-for="company in hospitality_operator.active"
			:key="'active-hospitality-operator-' + company.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( company, 'hospitality_operator' )"
		>
			{{ company.label }}
		</button>
		<button
			v-for="company in architect.active"
			:key="'active-architect-' + company.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( company, 'architect' )"
		>
			{{ company.label }}
		</button>
		<button
			v-for="company in landscape_architect.active"
			:key="'active-landscape-architect-' + company.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( company, 'landscape_architect' )"
		>
			{{ company.label }}
		</button>
		<button
			v-for="company in interior_design.active"
			:key="'active-interior-design-' + company.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( company, 'interior_design' )"
		>
			{{ company.label }}
		</button>
		<button
			v-for="user in project_manager.active"
			:key="'active-project-manager-' + user.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( user, 'project_manager' )"
		>
			{{ user.label }}
		</button>
		<button
			v-for="user in account_director.active"
			:key="'active-account-director-' + user.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( user, 'account_director' )"
		>
			{{ user.label }}
		</button>
		<button
			v-for="user in lead_artist.active"
			:key="'active-project-director-' + user.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( user, 'lead_artist' )"
		>
			{{ user.label }}
		</button>
		<button
			v-for="user in business_development.active"
			:key="'active-project-director-' + user.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( user, 'business_development' )"
		>
			{{ user.label }}
		</button>
		<button
			v-for="option in published.active"
			:key="'active-published-option-' + option.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( option, 'published' )"
		>
			{{ option.label }}
		</button>
		<button
			v-if="active_dropdown_filters.length > 1"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveAllFilters"
		>
			Clear all
		</button>
	</div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const filters_store = useProjectFiltersStore();

const {
	removeAllActiveDropdownFilters,
	removeActiveProjectStatus,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters
} = filters_store;

const {
	active_dropdown_filters,
	account_director,
	architect,
	business_development,
	client,
	completion_date,
	creative_agency,
	deliverables,
	developer,
	development_consultant,
	hospitality_operator,
	interior_design,
	landscape_architect,
	lead_artist,
	lead_office,
	project_location,
	project_manager,
	project_status,
	project_type,
	published,
	sales_marketing,
} = storeToRefs( filters_store );

const handleRemoveActiveFilterValue = ( value, handle ) => {
	removeActiveFilterValue( value, handle );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
}
const handleRemoveAllFilters = () => {
	removeAllActiveDropdownFilters();
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};
</script>