
<template>
	<div class="container">
		<Transition name="fade">
			<PageLoaderComponent v-if="loading_project" />

			<div v-else>
				<div class="flex flex--gap-large flex--align-center mb-32">
					<h1 class="project-title">
						{{ project.title }}
					</h1>
					<button
						class="favourite-project"
						:class="[
							{ 'favourite-project--selected': user_has_favourited_project },
							{ 'favourite-project--toggled': user_has_toggled_favourite },
							{ 'favourite-project--loading': loading_favourite }
						]"
						@click="toggleFavouriteCurrentProject"
					/>
					<button
						v-if="!user_is_in_directory"
						class="button"
						:class="{ 'button--disabled button--loading': setting_subscribed }"
						@click="toggleSubscribed"
					>
						{{ subscribed_label }}
					</button>

					<div
						v-if="!project.completed && !user_is_client"
						class="flex flex--justify-end flex--align-start flex--grow"
					>
						<button
							class="button"
							@click="show_upload_modal = !show_upload_modal"
						>
							Add media
						</button>
					</div>
				</div>

				<div class="flex flex--wrap flex--gap-large flex--justify-space-between mb-40">
					<TabNavigationComponent
						:tabs="tabs"
						:active-tab="active_tab"
						:no-margin="true"
						@set-tab="setRoute"
					/>

					<LightswitchComponent
						v-if="user_is_staff_super_admin"
						label="Completed"
						off-label="Completed"
						:checked="project.completed"
						:disabled="loading"
						@change="value => toggleProjectAsCompleted( value )"
					/>
				</div>

				<Transition name="fade">
					<PageLoaderComponent v-if="loading_tab" />

					<div v-else-if="active_tab === 'overview' && project && project.project_assets && project.project_assets.length">
						<div class="grid grid--xs flex flex--wrap mb-32">
							<div class="grid__item a8-12 d6-6">
								<button
									class="project-highlight-card project-highlight-card--image"
									@click="setRoute( 'grid' )"
								>
									<img
										v-if="latest_option && ( latest_option.image_large_url || latest_option.thumbnail_url )"
										:src="latest_option.image_large_url || latest_option.thumbnail_url"
										:alt="latest_option.title"
										class="project-highlight-card__image"
									>

									<div
										v-else
										class="project-highlight-card__image project-highlight-card__image--default"
									/>

									<div class="project-highlight-card__text">
										<p class="project-highlight-card__title">
											Latest images
										</p>
										<p class="project-highlight-card__action">
											View grid
										</p>
									</div>
								</button>
							</div>

							<div class="grid__item a4-12 d6-6">
								<button
									class="project-highlight-card project-highlight-card--image"
									@click="setRoute( 'submissions' )"
								>
									<img
										v-if="latest_submission_option"
										:src="latest_submission_option.image_large_url || latest_submission_option.thumbnail_url"
										:alt="latest_submission_option.title"
										class="project-highlight-card__image"
									>

									<div
										v-else
										class="project-highlight-card__image project-highlight-card__image--default"
									/>

									<div class="project-highlight-card__text">
										<p class="project-highlight-card__title">
											Latest submissions
										</p>
										<p class="project-highlight-card__action">
											View submissions
										</p>
									</div>
								</button>
							</div>

							<div class="grid__item a4-12 d6-6">
								<div class="project-highlight-card">
									<div class="project-highlight-card__text">
										<p class="project-highlight-card__title">
											Project information
										</p>
										<dl class="project-details">
											<div
												v-for="item, index in project_info"
												:key="`project-info-${ index }`"
												class="project-details__item"
											>
												<dt class="project-details__item-header">
													{{ item.label }}
												</dt>
												<dd class="project-details__item-content">
													<template v-if="item.label ==='Location'">
														<p>
															{{ item.content }}
														</p>
														<a
															v-if="item.link"
															:href="item.link"
															class="project-details__item-link"
															target="_blank"
															rel="noopener"
															aria-label="Open address in Google Maps"
														>
															Maps
														</a>
													</template>
													<template v-else>
														<a
															v-if="item.link"
															:href="item.link"
															target="_blank"
															rel="noopener"
															class="project-details__item-link"
														>
															{{ item.content }}
														</a>
														<p v-else>
															{{ item.content }}
														</p>
													</template>
												</dd>
											</div>
										</dl>
									</div>
								</div>
							</div>

							<div class="grid__item a4-12 d3-6 f6-6">
								<div class="project-highlight-card">
									<div class="project-highlight-card__text">
										<p class="project-highlight-card__title">
											Timetable
										</p>
										<dl class="project-details">
											<div
												v-for="item, index in project_timetable"
												:key="`project-info-${ index }`"
												class="project-details__item"
											>
												<dt class="project-details__item-header">
													{{ item.label }}
												</dt>
												<dd class="project-details__item-content">
													<p>
														{{ item.content }}
													</p>
												</dd>
											</div>
										</dl>
									</div>
								</div>
							</div>

							<div class="grid__item a4-12 d3-6 f6-6">
								<div class="project-highlight-card">
									<div class="project-highlight-card__text">
										<p class="project-highlight-card__title">
											Deliverables
										</p>
										<dl class="project-details">
											<div
												v-for="item, index in project_deliverables"
												:key="`project-info-${ index }`"
												class="project-details__item"
											>
												<dt class="project-details__item-header">
													{{ item.label }}
												</dt>
												<dd class="project-details__item-content">
													<p>
														{{ item.content }}
													</p>
												</dd>
											</div>
										</dl>
									</div>
								</div>
							</div>
						</div>
					</div>

					<SubmissionListComponent
						v-else-if="active_tab === 'submissions' && project && submissions && submissions.length"
						:submissions="submissions"
						:project="project"
					/>

					<ProjectAssetsComponent
						v-else-if="active_tab === 'grid' && project && project.project_assets && project.project_assets.length"
						:project="project"
						@refresh-project="refreshProject"
					/>

					<ProjectDiscussionsComponent
						v-else-if="active_tab === 'discussion' && project"
						:project="project"
					/>

					<ProjectDirectoryComponent
						v-else-if="active_tab === 'directory' && project"
						:project="project"
						@update-directory="updateDirectory"
					/>

					<ProjectTagsComponent
						v-else-if="active_tab === 'tags' && project"
						:project="project"
					/>

					<div v-else-if="active_tab === 'notifications'">
						<div class="flex flex--gap-large flex--align-center mb-32">
							<p>
								View:
							</p>
							<LightswitchComponent
								label="All"
								off-label="All"
								:checked="all_filters_active"
								:disabled="loading || loading_notifications"
								@change="toggleAllFilters"
							/>
							<LightswitchComponent
								v-for="filter, index in filters"
								:key="`filter-${index}`"
								:label="filter.label"
								:off-label="filter.label"
								:checked="filter.active"
								:disabled="loading || loading_notifications"
								@change="toggleFilter( filter )"
							/>
						</div>
						<NotificationsComponent
							:notifications="notifications"
							:pending-count="notifications_pagination.pending_count"
							:loading="loading_notifications"
							@mark-as-read="markNotificationAsRead"
							@mark-all-as-read="markNotificationsAsRead"
						/>

						<div
							v-show="notifications_pagination.total_count > notifications.length"
							ref="load_more_trigger"
							class="load-more-trigger"
						/>
					</div>

					<p v-else-if="!loading">
						No content.
					</p>
				</Transition>
			</div>
		</Transition>
	</div>

	<UploadModalComponent
		v-if="project"
		:show="show_upload_modal"
		:project="project"
		@close-modal="closeUploadModal"
	/>
</template>

<script>
import PageLoaderComponent from '../components/PageLoaderComponent.vue';
import TabNavigationComponent from '../components/TabNavigationComponent.vue';
import UploadModalComponent from '../components/uploads/UploadModalComponent.vue';
import ProjectAssetsComponent from '../components/ProjectAssetsComponent.vue';
import ProjectDiscussionsComponent from '../components/ProjectDiscussionsComponent.vue';
import ProjectDirectoryComponent from '../components/ProjectDirectoryComponent.vue';
import ProjectTagsComponent from '../components/ProjectTagsComponent.vue';
import NotificationsComponent from '../components/NotificationsComponent.vue';
import SubmissionListComponent from '../components/SubmissionListComponent.vue';
import LightswitchComponent from '../components/LightswitchComponent.vue';

import moment from 'moment';

import { useDataStore } from '../stores/data';
import { getProjectThumbnailOption } from '../../helpers';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useElementVisibility } from '@vueuse/core';
import { updatePageTitle } from '../../helpers';

import { USER_GROUP_STAFF, USER_GROUP_CLIENT, USER_GROUP_FREELANCER } from '../constants';

export default {
	components: {
		PageLoaderComponent,
		TabNavigationComponent,
		UploadModalComponent,
		ProjectAssetsComponent,
		ProjectDiscussionsComponent,
		ProjectDirectoryComponent,
		ProjectTagsComponent,
		NotificationsComponent,
		SubmissionListComponent,
		LightswitchComponent
	},
	setup() {
		const data_store = useDataStore();
		const {
			getProjectById,
			getSubmissionsByProjectId,
			getUserProfile,
			getNotifications,
			getPendingNotificationsCount,
			getUserRelatedNotificationsCount,
			markNotificationsAsRead,
			toggleFavouriteProject,
			toggleCompletedProject,
			setSubscribedProjects,
			setAlertMessage,
			setBreadcrumbs,
			isUserStaffSuperAdmin,
			user_group
		} = data_store;
		const { user_profile } = storeToRefs( data_store );

		const load_more_trigger = ref( null );
		const trigger_is_visible = useElementVisibility( load_more_trigger );

		return {
			user_profile,
			getProjectById,
			getSubmissionsByProjectId,
			getUserProfile,
			getNotifications,
			getPendingNotificationsCount,
			getUserRelatedNotificationsCount,
			markNotificationsAsRead,
			toggleFavouriteProject,
			toggleCompletedProject,
			setSubscribedProjects,
			setAlertMessage,
			setBreadcrumbs,
			isUserStaffSuperAdmin,
			user_group,
			load_more_trigger,
			trigger_is_visible
		};
	},
	data() {
		return {
			active_tab: 'overview',
			loading_project: true,
			loading_tab: true,
			loading_favourite: false,
			project: null,
			submissions: null,
			notifications: [],
			notifications_pagination: {
				page: 1,
				per_page: 10,
				unfiltered_pending_count: 0,
				pending_count: 0,
				total_count: 0
			},
			notifications_filters: [
				{ slug: 'discussions', label: 'Discussions', active: true },
				{ slug: 'uploads', label: 'Asset uploads', active: true },
				{ slug: 'client_approvals', label: 'Approvals', active: true },
				{ slug: 'submissions', label: 'Submissions', active: true }
			],
			loading_notifications: false,
			show_upload_modal: false,
			upload_modal_key: 'upload-modal-1',
			user_has_toggled_favourite: false,
			user_is_staff_super_admin: false,
			setting_subscribed: false,
			unfiltered_tabs: [
				{
					slug: 'overview',
					label: 'Overview'
				},
				{
					slug: 'submissions',
					label: 'Submissions'
				},
				{
					slug: 'grid',
					label: 'Grid'
				},
				{
					slug: 'tags',
					label: 'Tags'
				},
				{
					slug: 'discussion',
					label: 'Discussion',
				},
				{
					slug: 'directory',
					label: 'Directory',
				},
				{
					slug: 'notifications',
					label: 'Notifications',
					count: this.notifications_pagination ? this.notifications_pagination.unfiltered_pending_count : 0
				}
			]
		};
	},
	computed: {
		tabs() {
			if ( this.user_is_staff_super_admin ) {
				return this.unfiltered_tabs;
			}
			return this.unfiltered_tabs.filter( tab => tab.slug !== 'tags' );
		},
		latest_option() {
			if ( !this.project ) {
				return null;
			}
			return this.getLatestOption() || null;
		},
		latest_submission_option() {
			if ( !this.submissions || !this.submissions.length ) {
				return null;
			}
			return this.getLatestSubmissionOption() || null;
		},
		project_info() {
			return [
				{
					label: 'Project title',
					content: this.project.title
				},
				// TODO: Replace with entry/category fields
				// {
				// 	label: 'Location',
				// 	content: this.project.project_location[0] ? this.project.project_location[0].address_label : 'Unknown',
				// 	link: this.project.project_location[0] ? this.project.project_location[0].google_maps_link || null : null
				// },
				// {
				// 	label: 'Client',
				// 	content: this.project.project_client[0] ? this.project.project_client[0].client_name : 'Unknown',
				// 	link: this.project.project_client[0] ? this.project.project_client[0].client_url || null : null,
				// }
			];
		},
		project_timetable() {
			return [
				{
					label: 'Kick-off',
					content: this.project.kick_off ? this.formatProjectDate( this.project.kick_off ) : 'Unknown'
				},
				{
					label: 'Target completion',
					content: this.project.target_completion ? this.formatProjectDate( this.project.target_completion ) : 'Unknown'
				},
				{
					label: 'Duration',
					content: this.formatProjectDuration( this.project.kick_off, this.project.target_completion )
				}
			];
		},
		project_deliverables() {
			return [
				{
					label: 'Initial scope',
					content: this.project.initial_scope || 'Unknown'
				},
				{
					label: 'Still image',
					content: this.project.still_image_fee || 'Unknown'
				},
				{
					label: 'Total project fee',
					content: this.project.total_project_fee || 'Unknown'
				},
			];
		},
		user_has_favourited_project() {
			return this.user_profile.favourite_projects.find( project => parseInt( project.id ) === parseInt( this.$route.params.project_id ) );
		},
		user_is_staff() {
			return this.user_group === USER_GROUP_STAFF;
		},
		user_is_client() {
			return this.user_group === USER_GROUP_CLIENT;
		},
		user_is_freelancer() {
			return this.user_group === USER_GROUP_FREELANCER;
		},
		filters() {
			if ( this.user_is_staff ) {
				return this.notifications_filters.filter( filter => filter.slug !== 'submissions' );
			}
			return this.notifications_filters.filter( filter => filter.slug !== 'uploads'  && filter.slug !== 'client_approvals' )
		},
		all_filters_active() {
			return this.filters.every( filter => filter.active === true );
		},
		user_is_in_directory() {
			const all_directory_users = [
				...this.project.account_director,
				...this.project.project_manager,
				...this.project.lead_artist,
				...this.project.project_artists,
				...this.project.clients
			];
			return all_directory_users.find( user => +user.id === +this.user_profile.id );
		},
		user_is_subscribed() {
			return this.user_profile.subscribed_projects.find( project => +project.id === +this.project.id );
		},
		subscribed_label() {
			if ( this.user_is_subscribed ) {
				return 'Remove from My Projects';
			}
			return 'Add to My Projects';
		}
	},
	watch: {
		$route( to, from ) {
			this.setAlertMessage( null );
			if ( !to.query.tab ) {
				return;
			}
			if ( to.query.tab === from.query.tab ) {
				return;
			}
			this.setTab( to.query.tab );
		},
		async trigger_is_visible( new_value, old_value ) {
			if ( new_value && !old_value && !this.loading_notifications ) {
				await this.getProjectNotifications( {
					page: this.notifications_pagination.page + 1,
					per_page: this.notifications_pagination.per_page
				} );
			}
		}
	},
	async mounted() {
		await this.getProject();
		updatePageTitle( this.project.title );
		this.user_is_staff_super_admin = await this.isUserStaffSuperAdmin();

		await this.getSubmissions();

		this.loading_project = false;

		const breadcrumbs = [
			{
				label: 'Projects',
				route: {
					name: 'projects'
				},
				icon: 'briefcase'
			},
			{
				label: this.project.title,
			}
		];

		if ( this.$route.query && this.$route.query.tab ) {
			this.active_tab = this.$route.query.tab;

			if ( this.$route.query.tab !== 'overview' ) {
				breadcrumbs.push( { label: this.tabs.find( tab => tab.slug === this.$route.query.tab ).label } );
			}

			if ( this.$route.query.tab === 'notifications' ) {
				await this.getProjectNotifications( {
					page: 1,
					per_page: this.notifications_pagination.per_page
				} );
			}
		}

		this.setBreadcrumbs( breadcrumbs );

		this.loading_tab = false;
	},
	methods: {
		async refreshProject() {
			this.loading_tab = true;
			try {
				const response = await this.getProjectById( this.$route.params.project_id );
				if ( response.data ) {
					this.project = response.data.entry;
				}
			} catch ( error ) {
				this.$router.push( {
					name: 'projects'
				} );
			}
			this.loading_tab = false;
		},
		setRoute( slug ) {
			this.$router.push( {
				name: 'projects__project',
				params: { project_id: this.$route.params.project_id },
				query: { tab: slug }
			} );
		},
		async setTab( slug ) {
			this.loading_tab = true;
			this.active_tab = slug;

			if ( slug === 'notifications' ) {
				await this.getProjectNotifications( {
					page: 1,
					per_page: this.notifications_pagination.per_page
				} );
			}

			const breadcrumbs = [
				{
					label: 'Projects',
					route: {
						name: 'projects'
					},
					icon: 'briefcase'
				},
				{
					label: this.project.title,
				}
			];

			if ( slug !== 'overview' ) {
				breadcrumbs.push( { label: this.tabs.find( tab => tab.slug === slug ).label } );
			}

			await this.setBreadcrumbs( breadcrumbs );

			this.loading_tab = false;
		},
		async getProject() {
			try {
				const response = await this.getProjectById( this.$route.params.project_id );
				if ( response.data ) {
					this.project = response.data.entry;
				}
				const unfiltered_notification_count = await this.getPendingNotificationsCount( {
					project_ids: [ parseInt( this.$route.params.project_id ) ]
				} );
				this.notifications_pagination.unfiltered_pending_count = unfiltered_notification_count;
			} catch ( error ) {
				this.$router.push( {
					name: 'projects'
				} );
			}
		},
		async getSubmissions() {
			try {
				const response = await this.getSubmissionsByProjectId( this.$route.params.project_id );
				if ( response.data ) {
					this.submissions = response.data.entries;
				}
			} catch ( error ) {
				// console.log( error );
			}
		},
		async getProjectNotifications( { page, per_page } ) {
			this.loading_notifications = true;
			try {
				const response = await this.getNotifications( {
					project_ids: [ parseInt( this.$route.params.project_id ) ],
					types: this.getFormattedNotificationTypes(),
					page,
					per_page
				} );
				if ( response.data && response.data.notifications ) {
					if ( page > 1 ) {
						this.notifications.push( ...response.data.notifications );
					} else {
						this.notifications = response.data.notifications;
					}
					this.notifications_pagination.page = page;
					this.notifications_pagination.per_page = per_page;
					this.notifications_pagination.pending_count = response.data.pendingNotificationsTotal;
					this.notifications_pagination.total_count = response.data.notificationsTotal;
				}
			} catch ( error ) {
				// console.log( error );
			}
			this.loading_notifications = false;
		},
		getLatestOption() {
			return getProjectThumbnailOption( this.project );
		},
		getLatestSubmissionOption() {
			const latest_submission = this.submissions
				.filter( submission => submission.options.length )
				.at( 0 )
			;
			if ( !latest_submission ) {
				return null;
			}

			const sorted_options = latest_submission.options
				.slice()
				.sort( ( a,b ) => a.title > b.title ? 1 : -1 );
			const latest_option = sorted_options.at( -1 );

			return latest_option || null;
		},
		closeUploadModal() {
			this.show_upload_modal = false;
		},
		formatProjectDate( date ) {
			return moment( new Date( date ) ).format( 'D MMMM YYYY' );
		},
		formatProjectDuration( kick_off, completion_date ) {
			if ( !kick_off || !completion_date ) {
				return 'Unknown';
			}
			const start = moment( new Date( kick_off ) );
			const end = moment( new Date( completion_date ) );
			return start.to( end, true );
		},
		async toggleProjectAsCompleted( value ) {
			try {
				const response = await this.toggleCompletedProject( {
					project_id: this.project.id,
					completed: value
				} );
				if ( response && response.data && response.data.save_projects_default_Entry ) {
					this.project.completed = response.data.save_projects_default_Entry.completed;
					const message = this.project.completed ? 'This project is now marked as completed and further editing has been disabled' : 'This project is now no longer marked as completed and editing is allowed';
					this.setAlertMessage( message );
				}
			} catch( error ) {
				// console.log( error );
			}
		},
		async toggleFavouriteCurrentProject() {
			this.user_has_toggled_favourite = false;
			this.loading_favourite = true;
			try {
				const response = await this.toggleFavouriteProject( parseInt( this.project.id ) );
				if ( response.data ) {
					await this.getUserProfile();
				}
			} catch ( error ) {
				// console.log( error );
			}
			this.user_has_toggled_favourite = true;
			this.loading_favourite = false;
		},
		async toggleSubscribed() {
			let project_ids = [
				...this.user_profile.subscribed_projects.map( project => +project.id ),
				+this.project.id
			];
			if ( this.user_is_subscribed ) {
				project_ids = project_ids.filter( id => +id !== +this.project.id );
			}

			this.setting_subscribed = true;
			await this.setSubscribedProjects( project_ids );
			this.setting_subscribed = false;

			this.getUserRelatedNotificationsCount();
		},
		markNotificationAsRead( notification_id ) {
			const notification = this.notifications.find( notification => notification.id === notification_id );
			if ( !notification ) {
				return;
			}
			this.notifications[ this.notifications.indexOf( notification ) ].read = true;
			this.notifications_pagination.pending_count = this.notifications_pagination.pending_count - 1;
		},
		async markNotificationsAsRead() {
			const response = await this.markNotificationsAsRead( {
				project_ids: [ parseInt( this.$route.params.project_id ) ]
			} );
			if ( !response || !response.readNotifications  ) {
				return;
			}
			this.notifications = [ ...this.notifications ].map( notification => {
				return {
					...notification,
					read: true
				};
			} );
			this.notifications_pagination.pending_count = 0;
			await this.getUserRelatedNotificationsCount();
		},
		getFormattedNotificationTypes() {
			const formatted_types = [];
			const types = {
				'discussions': ['OptionCommentPosted', 'DiscussionCommentPosted', 'DiscussionCreated'],
				'client_approvals': ['OptionClientApproved', 'RevisionClientApproved'],
				'uploads': ['UploadAdded'],
				'submissions': ['SubmissionCreated']
			};
			this.filters.forEach( filter => {
				if ( filter.active === true ) {
					formatted_types.push( ...types[ filter.slug ] )
				}
			});
			return formatted_types;
		},
		async toggleAllFilters() {
			if ( this.all_filters_active ) {
				this.filters.forEach( filter => {
					if ( filter.active ) {
						this.setProjectNotificationFilter( filter.slug, false );
					}
				} );
			} else {
				this.filters.forEach( filter => {
					if ( !filter.active ) {
						this.setProjectNotificationFilter( filter.slug, true );
					}
				} );
			}

			this.notifications = [];
			await this.getProjectNotifications( {
				page: 1,
				per_page: this.notifications_pagination.per_page
			} );
		},
		async toggleFilter( filter ) {
			this.setProjectNotificationFilter( filter.slug, !filter.active );

			this.notifications = [];
			await this.getProjectNotifications( {
				page: 1,
				per_page: this.notifications_pagination.per_page
			} );
		},
		setProjectNotificationFilter( slug, active ) {
			const index = this.notifications_filters.indexOf( this.notifications_filters.find( filter => filter.slug === slug ) );
			this.notifications_filters[index].active = active;
		},
		updateDirectory( directory ) {
			for (const [ key, value ] of Object.entries( directory ) ) {
				if ( this.project[key] ) {
					this.project[key] = value;
				}
			}
		}
	}
};

</script>
